import React, { useContext, useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Scroll from "react-scroll";
import ItemContainer from "../../components/Item";

import { CartContext } from "../../context";
import { useUpdateCartQty } from "../../hooks";
import _ from "lodash";
import { SearchContext } from "../../context";

import SearchBar from "./searchBar";
import { usePriceFormat, useLocalization } from "../../hooks";

import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

import fetchMenu from "../../queries/fetchMenu";
import fetchModifiers from "../../queries/fetchModifiers"; // Import fetchModifiers
import { mapCategories } from "../../../createPages/mainPage/mapData"; // Import mapCategories

const OrderOnline = ({ menu }) => {
  // Menu state
  const [menuData, setMenuData] = useState(menu);

  // Log the initial menu prop
  console.log("Initial menu prop: ", menu);

  // Fetch menu and update state only once
  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    const fetchAndUpdateMenu = async () => {
      console.log("Fetching menu data..."); // Development log
      const menuResponse = await fetchMenu(menu.id);
      const modifiersResponse = await fetchModifiers(); // Fetch modifiers

      // Create a map of modifiers for quick lookup by ID
      const modifiersMap = modifiersResponse.data.modifiers.reduce((map, modifier) => {
        map[modifier.id] = modifier;
        return map;
      }, {});

      // Combine menu and modifiers data
      const cats = await Promise.all(
        menuResponse.data.menu.categories.map(async (cat) => {
          return await {
            ...cat,
            items: await Promise.all(
              cat.items.map(async (item) => {
                return await {
                  ...item,
                  modifiers: await Promise.all(
                    item.modifiers.map(async (modifier) => {
                      return modifiersMap[modifier.id];
                    }),
                  ),
                };
              }),
            ),
          };
        }),
      );

      // Process fetched data to match the structure expected by the application
      const categoryData = mapCategories(cats || []);

      if (isMounted) {
        setMenuData({ categories: categoryData } || { categories: [] });
      }
    };

    fetchAndUpdateMenu();

    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
    };
  }, [menu.id]);

  const ref = useRef(null); // Reference to wrapping div
  const { events } = useDraggable(ref);

  const { locale: currentLocale } = useLocalization(); // Current locale

  const { cartLineItems } = useContext(CartContext); // Cart context
  const [categories, setCategories] = useState([]); // Categories state
  const [filterItems, setFilterItems] = useState({}); // Filter items state
  const getQty = useUpdateCartQty(); // Hook to update cart quantity
  const { searchResult } = useContext(SearchContext); // Search context
  const { priceSymbol } = usePriceFormat(); // Price format

  // Update categories and filter items based on search results and locale
  useEffect(() => {
    if (_.isEmpty(currentLocale)) return;
    if (_.isEmpty(searchResult)) {
      setFilterItems({});
      setCategories(menuData.categories);
      return;
    }
    const catNames = _.keys(_.groupBy(searchResult, "categoryName"));
    setFilterItems(_.groupBy(searchResult, "id"));
    const _cats = _.filter(
      menuData.categories,
      (c) => _.indexOf(catNames, c[currentLocale].name) > -1,
    );
    setCategories(_cats);
  }, [searchResult, menuData.categories, currentLocale]);

  // Calculate quantity of items in the cart
  const itemsQty = useMemo(() => {
    if (_.isEmpty(cartLineItems)) return {};

    let _itemsQty = {};
    _.map(cartLineItems, (v, k) => {
      if (_itemsQty[v.localeShareId]) _itemsQty[v.localeShareId] += v.quantity;
      else _itemsQty[v.localeShareId] = v.quantity;
    });
    return _itemsQty;
  }, [cartLineItems]);

  // Register and remove scroll events
  useEffect(() => {
    Scroll.Events.scrollEvent.register("begin", function () {});
    Scroll.Events.scrollEvent.register("end", function () {});
    Scroll.scrollSpy.update();
    return () => {
      Scroll.Events.scrollEvent.remove("begin");
      Scroll.Events.scrollEvent.remove("end");
    };
  }, []);

  // ================================================================
  return (
    <>
      {/* BEGIN MENU CAT */}
      <div className="cat-menu-div ontop" style={{ background: "white" }}>
        {/* <div className="recommendedArea searchMenuBarDiv">
       
        </div> */}
        <SearchBar />
        <div className="scrollmenu" id="scrollmenu" ref={ref} {...events}>
          {categories.map((v, i) => {
            return (
              <React.Fragment key={i}>
                <Scroll.Link
                  activeClass="active-menu-cat"
                  spy={true}
                  to={_.snakeCase(v[currentLocale]?.name)}
                  name={_.snakeCase(v[currentLocale]?.name) + "-button"}
                  smooth={true}
                  offset={-250}
                  duration={250}
                  onClick={() => {}}
                  onSetActive={(e, a) => {
                    Scroll.scroller.scrollTo(
                      _.snakeCase(v[currentLocale]?.name) + "-button",
                      {
                        duration: 250,
                        smooth: true,
                        containerId: "scrollmenu",
                        horizontal: true,
                      },
                    );
                  }}
                  onSetInactive={(e) => {}}
                >
                  <span className="cat-name">{v[currentLocale]?.name}</span>
                </Scroll.Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <br />

      <section className="offer-dedicated-body pt-2OFF pb-2 mt-4OFF mb-4 padMe3">
        {/* MOD AK CONTAINER */}
        <div>
          <Row>
            <Col lg={12}>
              <div className="offer-dedicated-body-left">
                {/* MENU ITEMS */}
                <div>
                  {categories &&
                    categories.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Scroll.Element name={_.snakeCase(v[currentLocale]?.name)}>
                            <h2
                              id={_.snakeCase(v[currentLocale]?.name)}
                              className="mb-4 mt-3 menu-cat-title"
                            >
                              {v[currentLocale]?.name}

                              {/* <small className="h6 text-black-50 pl-1 h7">
                          ({_.isArray(v.items) && v.items.length} items)
                        </small> */}
                            </h2>
                            {/* <div className="bg-white rounded borderOFF shadow-smOFF mb-4"> */}
                            <Row>
                              {
                                // if the items is an array and the length is greater than 0
                                // loop through the items and render the item container
                                // else render the empty container
                              }
                              {_.isArray(v.items) &&
                                v.items.length > 0 &&
                                v.items.map((itemV, itemI) => {
                                  // Render the item container if filter items is empty or contains the item id
                                  if (
                                    _.isEmpty(filterItems) ||
                                    (!_.isEmpty(filterItems) &&
                                      filterItems[itemV[currentLocale]?.id])
                                  )
                                    return (
                                      <>
                                        <Col xl={4} lg={6} md={6} key={itemI}>
                                          <ItemContainer
                                            id={itemV[currentLocale]?.id}
                                            title={itemV[currentLocale]?.name}
                                            localeShareId={
                                              itemV[currentLocale]?.localeShareId
                                            }
                                            subTitle={itemV[currentLocale]?.description}
                                            price={itemV[currentLocale]?.price}
                                            image={itemV[currentLocale]?.photo?.url || ""}
                                            priceUnit={priceSymbol}
                                            quantity={
                                              itemsQty?.[
                                                itemV[currentLocale]?.localeShareId
                                              ] || 0
                                            }
                                            getValue={getQty}
                                            modifiers={itemV.modifiers}
                                            availability={
                                              itemV[currentLocale]?.availability
                                            }
                                          />
                                        </Col>
                                      </>
                                    );
                                  // else render the empty container
                                  else return <Col xl={4} lg={6} md={6} key={itemI}></Col>;
                                })}
                            </Row>
                            <br />
                            {/* </div> */}
                          </Scroll.Element>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
export default OrderOnline;
